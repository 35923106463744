import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { documentToReactComponents, RenderNode } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { useSpring } from '@react-spring/web'

import { FaqContent } from 'services/api/faq'

import { FaqToggle } from './FaqToggle'

const FaqTitle = styled.span`
  color: ${({ theme }) => theme.colors.black};
  font-weight: bold;
  line-height: 24px;
  font-size: 16px;
  white-space: normal;
  word-wrap: break-word;
  max-width: 75%;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 20px;
    line-height: 32px;
  }
`

const FaqItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top: 1px solid ${({ theme }) => theme.colors.gray};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
  padding: ${({ theme }) => theme.spacing(3)}px;
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;

  :hover {
    background-color: ${({ theme }) => theme.colors.backgroundGray};
    background-opacity: 0.5;
  }
`

const FaqTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const FaqContentContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  font-size: 18px;
`

interface ContentfulEmbeddedAssetProps {
  src: string
  height: number
  width: number
  alt: string
  imageStyle?: React.CSSProperties
}

const ContentfulEmbeddedAssetContainer = styled.img`
  object-fit: contain;
  max-width: 100%;
  height: auto;
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray};
`

const ContentfulEmbeddedAsset: React.VFC<ContentfulEmbeddedAssetProps> = ({
  imageStyle,
  ...props
}) => {
  return <ContentfulEmbeddedAssetContainer style={imageStyle} {...props} />
}

const CONTENTFUL_RICH_TEXT_CUSTOM_RENDERERS: RenderNode = {
  // eslint-disable-next-line react/display-name
  [BLOCKS.EMBEDDED_ASSET]: node => {
    return (
      <ContentfulEmbeddedAsset
        src={`https://${node.data.target.fields.file.url}`}
        height={node.data.target.fields.file.details.image.height}
        width={node.data.target.fields.file.details.image.width}
        alt={node.data.target.fields.description}
      />
    )
  },
}

interface FaqItemProps extends FaqContent {
  isSelected?: boolean
  onSelect: (() => void) | (() => Promise<void>)
  onClose: (() => void) | (() => Promise<void>)
}

export const FaqItem: React.VFC<FaqItemProps> = ({
  question,
  answer,
  id,
  isSelected,
  onClose,
  onSelect,
}) => {
  const faqItemRef = useRef<HTMLDivElement>(null)

  const toggleStyles = useSpring({
    transform: `rotate(${isSelected ? 0 : 45}deg)`,
  })

  useEffect(() => {
    const faqItem = faqItemRef.current

    if (isSelected && id && window.location.hash === `#${id}` && faqItem) {
      const parentContainer = faqItem.parentElement

      if (parentContainer) {
        const elementOffsetTop = faqItem.offsetTop
        const parentOffsetTop = parentContainer.offsetTop

        parentContainer.scrollTo({
          behavior: 'smooth',
          top: elementOffsetTop - parentOffsetTop,
        })
      }
    }
  }, [isSelected, faqItemRef])

  return (
    <FaqItemContainer id={`#${id}`} ref={faqItemRef}>
      <FaqTitleContainer>
        <FaqTitle>{question}</FaqTitle>
        <FaqToggle style={toggleStyles} onToggle={isSelected ? onClose : onSelect} />
      </FaqTitleContainer>
      {isSelected && (
        <FaqContentContainer>
          {documentToReactComponents(answer, { renderNode: CONTENTFUL_RICH_TEXT_CUSTOM_RENDERERS })}
        </FaqContentContainer>
      )}
    </FaqItemContainer>
  )
}
